import React, { useState, useEffect } from "react";
import { Router, Link } from "@reach/router";
import "./App.css";
import "./fonts.css";
import Header from "./Header.js";
import ProductListing from "./ProductListing.js";
import CartPage from "./CartPage.js";

const App = () => {
  // intended to use a map but began with object. Switching it would remember insertion order.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [cart, setCart] = useState({});
  const [total, setTotal] = useState(0);
  const [retailValue, setRetailValue] = useState(0);

  const handleAddTocart = productDetails => {
    const productId = productDetails.id;

    if(cart.hasOwnProperty(productId)) {
      setCart(prevState => ({
        ...prevState,
        [productId]: {
          ...productDetails,
          'quantity': prevState[productId]['quantity'] + 1,
        },
      }));
    }
    else {
      setCart(prevState => ({
        ...prevState,
        [productId]: {
          ...productDetails,
          'quantity': 1,
        },
      }));
    }
  };

  const handleUpdateProductQuantity = (id, newQuantity) => {
    if(cart.hasOwnProperty(id)) {
      const newProduct = Object.assign({}, cart[id]);
      newProduct.quantity = parseInt(newQuantity);

      setCart(prevState => ({
        ...prevState,
        [id]: newProduct,
      }));
    }
  };

  const handleRemoveProduct = (id) => {
    const newCart = Object.assign({}, cart);
    delete newCart[id];
    setCart(newCart);
  }

  useEffect(() => {
    let newTotal = Object.entries(cart).map((item) => {
      return item[1].quantity * item[1].currentPrice;
    })
    .reduce((result, price) => {
      return result + price;
    }, 0);

    setTotal(newTotal.toFixed(2));
  }, [cart]);

  useEffect(() => {
    let newRetailValue = Object.entries(cart).map((item) => {
      return item[1].quantity * item[1].retailValue;
    })
    .reduce((result, price) => {
      return result + price;
    }, 0);

    setRetailValue(newRetailValue.toFixed(2));
  }, [cart]);

  return (
    <div className="App">
      <Header cart={cart} />
      <Router>
        <ProductListing 
          handleAddToCart={handleAddTocart} 
          path="/" 
        />
        <CartPage 
          cart={cart} 
          total={total}
          retailValue={retailValue}
          handleUpdateProductQuantity={handleUpdateProductQuantity} 
          handleRemoveProduct={handleRemoveProduct}
          path="cart" 
        />
      </Router>
    </div>
  );
};

export default App;
