import React, { useState, useEffect } from "react";
import { Router, Link } from "@reach/router";
import logo from "./assets/logo.svg";
import cartIcon from "./assets/cart.svg";

const Header = (props) => {
  const { cart } = props;
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    let newQuantity = Object.entries(cart).map((item) => {
      // Get item from product id
      return item[1].quantity;
    })
    .reduce((result, quantity) => {
      return result + quantity
    }, 0);

    setCartQuantity(newQuantity);
  }, [cart]);

  return (
    <header className="header">
      <div className="header__logo">
        <Link to="/"><img src={logo} alt="logo" /></Link>
      </div>
      <div className="header__cart">
        <Link to="cart">
          <button className="header__cart-button" >
            <img src={cartIcon} alt="cart icon" />
            <span className="screen-reader-text">View Cart</span>
            {
              cartQuantity > 0 ? 
              <span className="header__cart-item-count">{cartQuantity}</span> : null
            }
          </button>
        </Link>
      </div>
    </header>
  );
};

export default Header;

//
// Possible additions
//
// 1. Loader/spinner/notice for added to cart