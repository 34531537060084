import React, { useState, useEffect } from "react";
import { calcDiscountPrice } from "./Utils.js";


const CartProduct = (props) => {
  const { 
    item,
    handleUpdateProductQuantity,
    handleRemoveProduct,
  } = props;

  const {
    brand,
    currentPrice,
    previewImage,
    retailValue,
    title,
    quantity,
    id,
  } = item;

  const discountPrice = calcDiscountPrice(currentPrice, retailValue);
  const currentPriceCalc = (currentPrice * quantity).toFixed(2);
  const retailPriceCalc = (retailValue * quantity).toFixed(2);
  const savePriceCalc = ((retailValue - currentPrice) * quantity).toFixed(2);

  return (
    <div className="cart-product">
      <div className="cart-product--left">
        <img className="cart-product__image" src={previewImage} alt={title} />
        <div className="cart-product__discount">({discountPrice}% off)</div>
      </div>
      <div className="cart-product--right">
        <p className="cart-product__brand">{brand}</p>
        <h2 className="cart-product__title">{title}</h2>
        <div className="cart-product__inputs">
          <label htmlFor="product-qty">Quantity: </label>
          <select name="product-qty" id="product-qty" onChange={(e) => handleUpdateProductQuantity(id, e.target.value)} value={quantity}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <button className="cart-product__remove-btn" onClick={() => handleRemoveProduct(id)}>&times; remove</button>
        </div>
        <div className="cart-product__prices">
          <div className="cart-product__current-price">${currentPriceCalc}</div>
          <div className="cart-product__retail-value">${retailPriceCalc}</div>
          <div className="cart-product__saved">Save: ${savePriceCalc}</div>
        </div>
      </div>
    </div>
  );
}

export default CartProduct;
