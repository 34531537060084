import React, { useState, useEffect } from "react";
import CartProduct from "./CartProduct.js";

const CartPage = (props) => {
  const { 
    cart,
    handleUpdateProductQuantity,
    handleRemoveProduct,
    total,
    retailValue,
  } = props;

  const [items, setItems] = useState([]);

  useEffect(() => {
    let newItems = Object.entries(cart).map((item) => {
      // Get item from product id
      item = item[1];

      return (
        <div className="cart__list-line-item" key={item.id}>
          <CartProduct 
            item={item} 
            handleUpdateProductQuantity={handleUpdateProductQuantity}
            handleRemoveProduct={handleRemoveProduct}
          />
        </div>
      );
    });

    setItems(newItems);
  }, [cart]);

  return (
    <div className="cart">
      <div className="cart__summary">
        <div className="cart__summary-total">Total: ${total}</div>
        <div className="cart__summary-savings">Savings: ${retailValue}</div>
      </div>
      <div className="cart__list">
        {items}
      </div>
    </div>
  )
}

export default CartPage

//
// Assumptions:
//
// 1. Prices (currentPrice, retailValue, saved) will change with quantity.
//   - If quantity is increased, the original single price isn't shown anymore

