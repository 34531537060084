import React, { useState, useEffect } from "react";
import Product from "./Product.js";
import catalog from "./data/catalog.json";

function getProductsFromPage(selectedPage = 1) {
  const resultsPerPage = 6;
  const start = parseInt((selectedPage - 1) * resultsPerPage);
  const end = parseInt(selectedPage * resultsPerPage);

  return catalog.products.slice(start, end);
}

const ProductListing = (props) => {
  const { handleAddToCart } = props;
  const [page, setPage] = useState(1);
  const [listItems, setListItems] = useState(getProductsFromPage(page));
  const [isFetching, setIsFetching] = useState(false);

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    setIsFetching(true);
  }

  function fetchMoreListItems() {
    setListItems((prevState) => [
      ...prevState,
      ...Array.from(getProductsFromPage(page)),
    ]);
  }

  useEffect(() => {
    setPage(1);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    setTimeout(() => {
      setPage(page + 1);
      setIsFetching(false);
    }, 500);
  }, [isFetching]);

  useEffect(() => {
    if (page !== 1) {
      fetchMoreListItems();
    }
  }, [page]);

  const firstPageProducts = listItems.map((productDetails) => {
    return (
      <Product
        key={productDetails.id}
        productDetails={productDetails}
        handleAddToCart={handleAddToCart}
      />
    );
  });

  return (
    <>
      <div className="product-listing">
        {firstPageProducts.length > 0
          ? firstPageProducts
          : "No products available"}
      </div>
      {isFetching && (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          Loading more products...
        </div>
      )}
    </>
  );
};

export default ProductListing;

//
// Possible improvements
//
// 1. Move infinite scroll to custom hook
// 2. Add scroll to top button (although some phones have built in features to scroll all the way up)

//
// Issues
//
// 1. Switching pages affects the pagination/order of items.
//   -- fix by using array instead or organize values in object
