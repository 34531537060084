import React, { useEffect } from "react";
import { calcDiscountPrice } from "./Utils.js";

function Product(props) {
  const { productDetails, handleAddToCart } = props;

  const { brand, currentPrice, previewImage, retailValue, title } =
    productDetails;

  const discountPrice = calcDiscountPrice(currentPrice, retailValue);

  //
  // Testing
  //
  // useEffect(() => {
  //   handleAddToCart(productDetails);
  //   console.log("add to cart");
  // }, []);

  return (
    <div className="product">
      <div className="product__image-wrapper">
        <div
          className="product__image-bg"
          style={{ backgroundImage: `url(${previewImage})` }}
        >
          <img
            className="product__image screen-reader-text"
            src={previewImage}
            alt={title}
          />
        </div>
      </div>
      <p className="product__brand">{brand}</p>
      <h2 className="product__title">{title}</h2>
      {/* There is a better way to format the price amount but going with this for scope */}
      <div className="product__bottom">
        <div className="product__price">
          ${currentPrice.toFixed(2)}
          <span className="product__retail-value">
            ${retailValue.toFixed(2)}
          </span>
        </div>
        <div className="product__discount">({discountPrice}% off)</div>
        <button onClick={() => handleAddToCart(productDetails)}>
          Add to Cart
        </button>
      </div>
    </div>
  );
}

export default Product;
